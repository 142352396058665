/***
 * FUNCTIONS
 ***/
// Check mobile
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

// DOWNLOAD_EBOOK
function download_ebook() {
    if ($('.Form._landing-page .info .download').length) {
        $('.Form._landing-page .info .download').show();
        $('.Form._landing-page .info .wpcf7').hide();
    } else {
        $('.wpcf7').addClass('send_ok');
    }
}

// ScrollAnim
function scrollAnim (target) {
    $(target).animatescroll({
        scrollSpeed: 800,
        easing: 'easeOutExpo',
        padding: 115,
    });
}


function loadPosts(pagina) {
    $(".Lista-posts .posts .more:last").load(pagina);

    $(this).remove();
}

/***
 * GERAL
 ***/
$(function() {

    // menu
    var $win = $(window),
        $menu = $('header nav'),
        $menuToggle = $('header .menuToggle');

    $($menuToggle).click(function(event) {
        event.preventDefault();
        $menu.slideToggle();
        $('html').toggleClass('open-menu');
    });

    $win.resize(function(event) {
        $('html').removeClass('open-menu');

        if ($win.width() <= 768) {
            $($menu).css('display', 'none');
        } else {
            $($menu).css('display', 'inline-block');
        }
    });

    // imagesLoaded
    $('body').imagesLoaded().always( function( instance ) {
        $('body').addClass('loaded');
    });

    // wow
    new WOW({
        offset: 300
    }).init();


    // owl carousel
    $('.owl-carousel').owlCarousel({
        items           : 1,
        loop            : true,
        autoplay        : false,
        dots            : true,
        nav             : false
    });

    // Galeria
    $('.Galeria').each(function() {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
              enabled:true
            }
        });
    });

    // Scrollspy
    $('body').scrollspy({
        target: 'header nav',
        offset: 300
    });

    // Scroll
    $('.scroll').on('click', function(event) {
        var data   = $(this).data('target');
        var href   = $(this).attr('href');
        var target = (data !== undefined) ? data : href;

        scrollAnim(target);
    });

    // Input masks
    $("input.cpf").mask("999.999.999-99");
    $("input.cnpj").mask("99.999.999/9999-99");
    $("input.data").mask("99/99/9999");
    $("input.cep").mask("99999-999");
    $("input.hora").mask("99:99");
    $("input.telefone").focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');

});
